import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';
export const Stammheim: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Stammheim,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Stammheim"
          d="M676,404l7,34l-10,4v-6l-10,2l-2,12l-12-12l-4,2l8,22l-4,14l-7.7,3.8l-1.3-3.2c-0.7-1.7-1.7-3.2-2.9-4.6l0,0 c-8.6-9.2-18.6-16.8-29.7-22.7c-5.8-3-11.7-6.1-16.4-8.4c-2.9-1.5-5.8-3-8.7-4.6c-1.7-0.9-3.5-2-5.3-3c-4-2.2-7.6-5.1-10.6-8.4l0,0 c-3.2-3.5-5.6-7-8.5-10.5c-2.6-3.1-4.9-5.4-5.8-10.4l0.8-0.1L571,400l8,18l12-6l12,8l44-10v-18l6,10L676,404z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 608.422 431.1758)"
        >
          Stammheim
        </text>
      </g>
    </>
  );
});
